
import {defineComponent, readonly} from 'vue';
import {useStore} from 'vuex';
import useTag from '@/components/tag/tag';
import Form from '@/components/form/Form.vue';
import FormItem from '@/components/form/FormItem.vue';
import {getPredefinedColors} from '@/utils/color';
import ColorPicker from '@/components/color/ColorPicker.vue';

export default defineComponent({
  name: 'TagForm',
  components: {ColorPicker, FormItem, Form},
  setup() {
    // store
    const store = useStore();

    // predefined colors
    const predefinedColors = readonly<string[]>(getPredefinedColors());

    return {
      ...useTag(store),
      predefinedColors,
    };
  },
});
