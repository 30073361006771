
import {defineComponent} from 'vue';
import TagForm from '@/components/tag/TagForm.vue';

export default defineComponent({
  name: 'TagDetailTabOverview',
  components: {
    TagForm,
  },
  setup() {
    return {};
  },
});
